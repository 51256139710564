import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c37594a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-row w-full" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "flex flex-col mb-auto" }
const _hoisted_5 = { class: "mt-4 ml-2 text-xs" }
const _hoisted_6 = { class: "mt-1 ml-2 text-xs" }
const _hoisted_7 = { class: "flex mb-3 justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "my-2 card rounded",
      src: _ctx.nft.externalMetadata.image,
      alt: _ctx.nft.onchainMetadata.data.name
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.nft.onchainMetadata.data.name), 1),
        _createElementVNode("p", _hoisted_6, " Rank: " + _toDisplayString(_ctx.mint_rank[_ctx.nft.mint.toBase58()]), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.staked)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "nes-btn is-error text-xs py-1 mx-3",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectNFT && _ctx.selectNFT(...args)))
            }, " Unstake "))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "nes-btn is-primary text-xs py-1 mx-3",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.selectNFT && _ctx.selectNFT(...args)))
            }, " Stake "))
      ])
    ])
  ]))
}