import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "nes-container with-title px-2" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "grid grid-cols-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NFTCard = _resolveComponent("NFTCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nfts, (nft) => {
        return (_openBlock(), _createBlock(_component_NFTCard, {
          key: nft,
          nft: nft,
          staked: _ctx.staked,
          selectedNFT: _ctx.selectedNFT,
          onSelected: _ctx.handleSelected
        }, null, 8, ["nft", "staked", "selectedNFT", "onSelected"]))
      }), 128))
    ])
  ]))
}